export const LOGIN_PATH = "/login"
export const RESET_PWD = "/rest-password"
export const RISK_TREND_DATA = [
    ["Jan", 1.194, 1.420],
    ["Feb", 1.185, 1.422],
    ["Mar", 1.171, 1.425],
    ["Apr", 1.227, 1.432],
    ["May", 1.223, 1.438],
    ["Jun", 1.24, 1.488],
    ["Jul", 1.28, 1.536],
    ["Aug", 1.266, 1.519],
    ["Sep", 1.294, 1.553],
    ["Oct", 1.299, 1.559],
    ["Nov", 1.296, 1.555],
    ["Dec", 1.307, 1.568]
]
export const RISK_TREND_DATA2 = [
    ["Jan", 1.307, 1.568],
    ["Feb", 1.296, 1.555],
    ["Mar", 1.299, 1.559],
    ["Apr", 1.294, 1.553],
    ["May", 1.266, 1.519],
    ["Jun", 1.28, 1.536],
    ["Jul", 1.24, 1.488],
    ["Aug", 1.223, 1.438],
    ["Sep", 1.227, 1.432],
    ["Oct", 1.171, 1.425],
    ["Nov", 1.185, 1.422],
    ["Dec", 1.194, 1.420]
]

export const REVENUE_DATA = [
    ["Jan", 1194, 1320],
    ["Feb", 1185, 1372],
    ["Mar", 1171, 1325],
    ["Apr", 1227, 1432],
    ["May", 1123, 1338],
    ["Jun", 1140, 1388],
    ["Jul", 1180, 1400],
    ["Aug", 1266, 1519],
    ["Sep", 1294, 1553],
    ["Oct", 1199, 1559],
    ["Nov", 1196, 1555],
    ["Dec", 1307, 1568],
  ]

  export const REVENUE_DATA2 = [
    ["Jan", 1307, 1568],
    ["Feb", 1196, 1555],
    ["Mar", 1199, 1559],
    ["Apr", 1294, 1553],
    ["May", 1266, 1519],
    ["Jun", 1180, 1400],
    ["Jul", 1140, 1388],
    ["Aug", 1123, 1338],
    ["Sep", 1227, 1432],
    ["Oct", 1171, 1325],
    ["Nov", 1185, 1372],
    ["Dec", 1194, 1320],
  ]

  export const stackData1 = [
    ["Beth", 80, 38],
    ["Mary", 100, 80],
    ["Susan", 80, 71],
    ["Nicole", 97, 81],
    ["Jennifer", 115, 91],
  ];

  export const stackData2 = [
    ["Beth", 20, 38],
    ["Mary", 20, 30],
    ["Susan", 8, 17],
    ["Nicole", 27, 41],
    ["Jennifer", 15, 32],
  ];


  export const barChartData1 = [
    ["Osteoporosis", 37],
    ["Renal Disease", 85],
    ["Anxiety Disorder", 48],
    ["CHF", 65],
    ["Joint Disorders", 69],
    ["Depression", 85],
    ["COPD", 78],
    ["Asthma", 69],
    ["Hypertension", 75],
    ["CAD", 89],
    ["Diabetes", 94],
  ];

  export const barChartData2 = [
    ["Osteoporosis", 33],
    ["Renal Disease", 14],
    ["Anxiety Disorder", 40],
    ["CHF", 74],
    ["Joint Disorders", 46],
    ["Depression", 25],
    ["COPD", 47],
    ["Asthma", 63],
    ["Hypertension", 57],
    ["CAD", 36],
    ["Diabetes", 43],
  ];

  export const RISK_TREND_DATA3 = [
    ["Jan", 1.194, 1.420],
    ["Feb", 1.185, 1.422],
    ["Mar", 1.171, 1.425],
    ["Apr", 1.227, 1.432],
    ["May", 1.223, 1.438],
    ["Jun", 1.24, 1.488],
    ["Jul", 1.28, 1.536],
    ["Aug", 1.266, 1.519],
    ["Sep", 1.294, 1.553],
    ["Oct", 1.299, 1.559],
    ["Nov", 1.296, 1.555],
    ["Dec", 1.307, 1.568]
]
export const RISK_TREND_DATA4 = [
    ["Jan", 1.307, 1.568],
    ["Feb", 1.296, 1.555],
    ["Mar", 1.299, 1.559],
    ["Apr", 1.294, 1.553],
    ["May", 1.266, 1.519],
    ["Jun", 1.28, 1.536],
    ["Jul", 1.24, 1.488],
    ["Aug", 1.223, 1.438],
    ["Sep", 1.227, 1.432],
    ["Oct", 1.171, 1.425],
    ["Nov", 1.185, 1.422],
    ["Dec", 1.194, 1.420]
]

export const REVENUE_DATA3 = [
    ["Jan", 1194, 1320],
    ["Feb", 1185, 1372],
    ["Mar", 1171, 1325],
    ["Apr", 1227, 1432],
    ["May", 1123, 1338],
    ["Jun", 1140, 1388],
    ["Jul", 1180, 1400],
    ["Aug", 1266, 1519],
    ["Sep", 1294, 1553],
    ["Oct", 1199, 1559],
    ["Nov", 1196, 1555],
    ["Dec", 1307, 1568],
  ]

  export const REVENUE_DATA4 = [
    ["Jan", 1307, 1568],
    ["Feb", 1196, 1555],
    ["Mar", 1199, 1559],
    ["Apr", 1294, 1553],
    ["May", 1266, 1519],
    ["Jun", 1180, 1400],
    ["Jul", 1140, 1388],
    ["Aug", 1123, 1338],
    ["Sep", 1227, 1432],
    ["Oct", 1171, 1325],
    ["Nov", 1185, 1372],
    ["Dec", 1194, 1320],
  ]

  export const stackData3 = [
    ["Beth", 80, 38],
    ["Mary", 100, 80],
    ["Susan", 80, 71],
    ["Nicole", 97, 81],
    ["Jennifer", 115, 91],
  ];

  export const stackData4 = [
    ["Beth", 20, 38],
    ["Mary", 20, 30],
    ["Susan", 8, 17],
    ["Nicole", 27, 41],
    ["Jennifer", 15, 32],
  ];


  export const barChartData3 = [
    ["Osteoporosis", 37],
    ["Renal Disease", 85],
    ["Anxiety Disorder", 48],
    ["CHF", 65],
    ["Joint Disorders", 69],
    ["Depression", 85],
    ["COPD", 78],
    ["Asthma", 69],
    ["Hypertension", 75],
    ["CAD", 89],
    ["Diabetes", 94],
  ];

  export const barChartData4 = [
    ["Osteoporosis", 33],
    ["Renal Disease", 14],
    ["Anxiety Disorder", 40],
    ["CHF", 74],
    ["Joint Disorders", 46],
    ["Depression", 25],
    ["COPD", 47],
    ["Asthma", 63],
    ["Hypertension", 57],
    ["CAD", 36],
    ["Diabetes", 43],
  ];
